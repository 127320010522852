import { useMemo } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import {
  PunchhFilterAttrs,
  SegmentableAttributes,
  Vendor,
  VendorAttribute,
  mParticleUnsupportedAttributes,
  punchUnsupportedAttributes,
  segmentIoUnsupportedAttributes,
  smileUnsupportedAttributes,
} from '../../../../constants';

import {
  separateKlaviyoOptions,
  separateShopifyOptions,
  useMemoizedSeparateOptions,
} from './utils';

type AttributeFlags =
  | 'ENABLE_MPARTICLE_ATTRIBUTES'
  | 'ENABLE_SMILE_ATTRIBUTES'
  | 'ENABLE_PUNCHH_ATTRIBUTES'
  | 'ENABLE_RECHARGE_ATTRIBUTES'
  | 'ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES'
  | 'ENABLE_YOTPO_ATTRIBUTES';

function useEnabledVendorAttributes(
  attributes: VendorAttribute[] | undefined,
  flagName: AttributeFlags,
  allowedAttributes?: string[]
) {
  const isEnabled = useCompanyFeatureFlag(flagName);

  return useMemo(() => {
    if (!isEnabled || !attributes) return [];
    if (allowedAttributes?.length) {
      return attributes.filter(({ attributeName }) => allowedAttributes.includes(attributeName));
    }
    return attributes;
  }, [allowedAttributes, attributes, isEnabled]);
}

export const useVendorAttributes = (vendorAttributes: SegmentableAttributes) => {
  const klaviyoOptions = useEnabledVendorAttributes(
    vendorAttributes.KLAVIYO,
    'ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES'
  );
  const punchhOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_PUNCHH,
    'ENABLE_PUNCHH_ATTRIBUTES'
  );
  const mParticleOptions = useEnabledVendorAttributes(
    vendorAttributes.MPARTICLE,
    'ENABLE_MPARTICLE_ATTRIBUTES'
  );
  const rechargeOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_RECHARGE,
    'ENABLE_RECHARGE_ATTRIBUTES'
  );
  const smileOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_SMILE_IO,
    'ENABLE_SMILE_ATTRIBUTES'
  );
  const yotpoOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_YOTPO,
    'ENABLE_YOTPO_ATTRIBUTES'
  );

  const { klaviyoEmailOptions, klaviyoLocationOptions, klaviyoProfileOptions } = useMemo(
    () => separateKlaviyoOptions(klaviyoOptions),
    [klaviyoOptions]
  );

  const punchhProfileOptions = useMemoizedSeparateOptions({
    options: punchhOptions,
    // We use knownOptions to include these options that are list datatype. The UI has special support for checkins
    knownOptions: [PunchhFilterAttrs.CHECKINS],
    vendor: Vendor.PUNCHH,
    unsupportedOptions: punchUnsupportedAttributes,
  });

  const mParticleProfileOptions = useMemoizedSeparateOptions({
    options: mParticleOptions,
    vendor: Vendor.MPARTICLE,
    unsupportedOptions: mParticleUnsupportedAttributes,
  });

  const rechargeProfileOptions = useMemoizedSeparateOptions({
    options: rechargeOptions,
    vendor: Vendor.RECHARGE,
    enableNestedListTypes: true,
  });

  const smileProfileOptions = useMemoizedSeparateOptions({
    options: smileOptions,
    vendor: Vendor.SMILE,
    unsupportedOptions: smileUnsupportedAttributes,
  });

  const yotpoProfileOptions = useMemoizedSeparateOptions({
    options: yotpoOptions,
    vendor: Vendor.YOTPO,
  });

  const segmentIoProfileOptions = useMemoizedSeparateOptions({
    options: vendorAttributes.VENDOR_SEGMENT || [],
    vendor: Vendor.SEGMENT_IO,
    unsupportedOptions: segmentIoUnsupportedAttributes,
  });

  const { shopifyEmailOptions, shopifyShoppingOptions } = useMemo(
    () => separateShopifyOptions(vendorAttributes.SHOPIFY),
    [vendorAttributes.SHOPIFY]
  );

  return {
    klaviyoEmailOptions,
    klaviyoLocationOptions,
    klaviyoProfileOptions,
    mParticleProfileOptions,
    punchhProfileOptions,
    rechargeProfileOptions,
    segmentIoProfileOptions,
    shopifyEmailOptions,
    shopifyShoppingOptions,
    smileProfileOptions,
    yotpoProfileOptions,
  };
};
