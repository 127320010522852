/**
 * @generated SignedSource<<962cc0d7561af26a466edc6bb1b69327>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGetSegmentCountMetric_TargetingCommon_Segment$data = {
  readonly segmentCount: {
    readonly email: {
      readonly activeSubscribers: number;
      readonly activeSubscribersNotFatigued: number;
    } | null;
    readonly sms: {
      readonly activeSubscribers: number;
      readonly activeSubscribersNotFatigued: number;
    };
    readonly smsAndEmail: {
      readonly activeSubscribers: number;
      readonly activeSubscribersNotFatigued: number;
    } | null;
  } | null;
  readonly " $fragmentType": "useGetSegmentCountMetric_TargetingCommon_Segment";
};
export type useGetSegmentCountMetric_TargetingCommon_Segment$key = {
  readonly " $data"?: useGetSegmentCountMetric_TargetingCommon_Segment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetSegmentCountMetric_TargetingCommon_Segment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribersNotFatigued",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGetSegmentCountMetric_TargetingCommon_Segment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SegmentCountPayload",
      "kind": "LinkedField",
      "name": "segmentCount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentCountItem",
          "kind": "LinkedField",
          "name": "sms",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentCountItem",
          "kind": "LinkedField",
          "name": "email",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentCountItem",
          "kind": "LinkedField",
          "name": "smsAndEmail",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Segment",
  "abstractKey": null
};
})();

(node as any).hash = "31e8139edc800f2c1e9d9a960da8d11f";

export default node;
