import cloneDeep from 'lodash/cloneDeep';
import React, { FC } from 'react';

import { Tracker, TrackerEvents } from '@attentive/acore-utils';
import { Box, Button, PicnicCss, styled } from '@attentive/picnic';

import {
  BlankSegment,
  Segment,
  SegmentOptions,
  SegmentParameters,
  SegmentComponentType,
  OperatorType,
  AvailableSegmentData,
  SegmentDuplicates,
  SegmentableAttributes,
  WHOLE_DUPLICATE_EXPRESSION,
  SegmentComponent,
} from '../../../../constants';
import { getSegmentType } from '../../../../utils';
import {
  HighlightConditionConfigs,
  isComponentHighlighted,
  isPreviousComponentHighlighted,
} from '../../utils/highlightConditions';
import SegmentComponentSeparator from '../SegmentComponentSeparator';
import { SegmentCondition } from '../SegmentCondition';
import SegmentConnectorPill from '../SegmentConnectorPill';

import { useShowExpressionValidationHandlers } from './hooks/useShowExpressionValidationHandlers';

export interface SegmentExpressionBuilderProps {
  segment: Segment | BlankSegment;
  updateCurrentSegment: (values: Partial<Segment>) => void;
  duplicates: SegmentDuplicates;
  validateDuplicates: boolean;
  segmentOptions: SegmentOptions;
  segmentableAttributesByVendor: SegmentableAttributes;
  availablSegmentData: AvailableSegmentData;
  highlightConditions?: HighlightConditionConfigs;
  isEditing: boolean;
}

const ConditionContainer = styled('div', {
  padding: '$space4',
  background: '$bgDefault',
  borderRight: '1px solid $borderDefault',
  borderLeft: '1px solid $borderDefault',
  variants: {
    first: {
      true: {
        borderTop: '1px solid $borderDefault',
        borderTopRightRadius: '$radius1',
        borderTopLeftRadius: '$radius1',
      },
      false: {
        pt: '$space8',
      },
    },
    last: {
      true: {
        borderBottom: '1px solid $borderDefault',
        borderBottomRightRadius: '$radius1',
        borderBottomLeftRadius: '$radius1',
      },
      false: {
        pb: '$space8',
      },
    },
    error: {
      true: {
        borderColor: '$borderInputError',
      },
    },
  },
});

const pillCss: PicnicCss = {
  m: '$space4',
  '&:before': {
    position: 'absolute',
    content: '""',
    width: 1,
    backgroundColor: '$borderDefault',
    height: '$size4',
    top: '-17px',
    left: '50%',
  },
  '&:after': {
    position: 'absolute',
    content: '""',
    width: 1,
    backgroundColor: '$borderDefault',
    height: '$size4',
    bottom: '-17px',
    left: '50%',
  },
};

const SegmentExpressionBuilder: FC<SegmentExpressionBuilderProps> = ({
  segment,
  updateCurrentSegment,
  duplicates,
  validateDuplicates,
  segmentOptions,
  segmentableAttributesByVendor,
  availablSegmentData: availableSegmentData,
  highlightConditions,
  isEditing,
}) => {
  const { handleFocus, handleBlur, setDialogIsOpen } = useShowExpressionValidationHandlers();

  const { expressions } = segment;

  const getBlankComponent = (): SegmentComponent => {
    return {
      parameters: {},
      type: SegmentComponentType.CUSTOM,
      description: '',
      metadata: {
        id: Date.now(),
      },
    };
  };

  const addExpression = () => {
    updateCurrentSegment({
      expressions: [
        ...expressions,
        {
          operator: OperatorType.OR,
          segmentComponents: [getBlankComponent()],
        },
      ],
    });
  };

  const addComponent = (expressionIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.push(getBlankComponent());
    updateCurrentSegment(newSegment);
  };

  const updateComponent = (
    expressionIdx: number,
    componentIdx: number,
    parameters: SegmentParameters
  ) => {
    const newSegment = cloneDeep(segment);
    const type = getSegmentType(parameters);
    const currentId =
      segment.expressions[expressionIdx].segmentComponents[componentIdx].metadata?.id;
    newSegment.expressions[expressionIdx].segmentComponents.splice(componentIdx, 1, {
      parameters,
      type,
      metadata: {
        id: currentId ?? Date.now(),
      },
    });
    updateCurrentSegment(newSegment);
  };

  const resetComponent = (expressionIdx: number, componentIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.splice(
      componentIdx,
      1,
      getBlankComponent()
    );
    updateCurrentSegment(newSegment);
  };

  const deleteComponent = (expressionIdx: number, componentIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.splice(componentIdx, 1);
    if (newSegment.expressions[expressionIdx].segmentComponents.length === 0) {
      newSegment.expressions.splice(expressionIdx, 1);
    }
    updateCurrentSegment(newSegment);
  };

  // todo: gs - this is a kludge, we need to refactor this component, and introduce a SegmentExpression component
  const showAndButton = expressions.every((expression) =>
    expression.segmentComponents.every(({ parameters }) => Object.keys(parameters).length > 0)
  );
  const segmentOperator = segment.operator;

  return (
    <Box css={{ mt: '$space4' }}>
      {expressions.map(({ id: expressionId, segmentComponents, operator }, expressionIdx) => {
        const expDupes = duplicates.get(expressionIdx);
        const expressionIsDupe = expDupes === WHOLE_DUPLICATE_EXPRESSION;
        const expressionKey = `exp-${expressionId}-${expressionIdx}`;

        return (
          <React.Fragment key={expressionKey}>
            <Box onFocus={handleFocus} onBlur={handleBlur}>
              {segmentComponents.map((component, componentIdx) => {
                const isFirstComponent = componentIdx === 0;
                const isLastComponent = componentIdx === segmentComponents.length - 1;
                const componentIsDupe = expDupes instanceof Set && expDupes.has(componentIdx);
                const prevComponentIsDupe =
                  expDupes instanceof Set && expDupes.has(componentIdx - 1);
                const showDeleteButton =
                  expressions.length > 1 || expressions[0].segmentComponents.length > 1;
                const showDupeText =
                  validateDuplicates && (componentIsDupe || (expressionIsDupe && isLastComponent));
                const isHighlightedComponent = isComponentHighlighted(
                  component,
                  highlightConditions
                );
                const isPreviousHighlightedComponent = isPreviousComponentHighlighted(
                  segmentComponents,
                  componentIdx,
                  highlightConditions
                );

                const separatorErrorState =
                  (validateDuplicates && (componentIsDupe || prevComponentIsDupe)) ||
                  isHighlightedComponent ||
                  isPreviousHighlightedComponent;

                const componentErrorState =
                  (validateDuplicates && (componentIsDupe || expressionIsDupe)) ||
                  isHighlightedComponent;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment
                    key={`component-${expressionId}-${component.id ?? component.metadata?.id}`}
                  >
                    {!isFirstComponent && (
                      <SegmentComponentSeparator error={separatorErrorState} operator={operator} />
                    )}
                    <ConditionContainer
                      first={isFirstComponent}
                      last={isLastComponent}
                      error={componentErrorState}
                      data-testid={componentErrorState ? 'segment-component-error' : undefined}
                    >
                      <SegmentCondition
                        availableData={availableSegmentData}
                        setDialogIsOpen={setDialogIsOpen}
                        showDupeText={showDupeText}
                        higlightCondition={isHighlightedComponent}
                        component={component}
                        segmentOptions={segmentOptions}
                        segmentableAttributesByVendor={segmentableAttributesByVendor}
                        showDeleteButton={showDeleteButton}
                        onChange={(params) => {
                          Tracker.track({
                            eventName: TrackerEvents.SEGMENT_CREATE_CONDITIONS_DEFINED,
                          });
                          updateComponent(expressionIdx, componentIdx, params);
                        }}
                        onClickOrButton={() => addComponent(expressionIdx)}
                        onClickDeleteButton={() => {
                          Tracker.track({
                            eventName: TrackerEvents.SEGMENT_CREATE_CONDITION_DELETED,
                          });
                          deleteComponent(expressionIdx, componentIdx);
                        }}
                        onClickFilterButton={(filterType) => {
                          Tracker.track({
                            eventName: TrackerEvents.SEGMENT_CREATE_FILTER_ADDED,
                            properties: { segmentId: segment.id, filterType },
                          });
                        }}
                        operator={operator}
                        resetComponent={() => resetComponent(expressionIdx, componentIdx)}
                        isEditing={isEditing}
                      />
                    </ConditionContainer>
                  </React.Fragment>
                );
              })}
            </Box>
            {expressionIdx < expressions.length - 1 ? (
              <SegmentConnectorPill operatorType={segmentOperator} css={pillCss} />
            ) : (
              showAndButton && (
                <Button
                  onClick={() => addExpression()}
                  size="small"
                  css={{ mt: '$space4' }}
                  data-segmentation-id="segment-builder-and-button"
                >
                  {segmentOperator === OperatorType.AND ? 'And' : 'Or'}
                </Button>
              )
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default SegmentExpressionBuilder;
