import { API, Response } from '@attentive/acore-utils';

import {
  BlankSegment,
  isSavedSegment,
  isSegment,
  LocationType,
  NumberValueDisplayableOption,
  Segment,
  SegmentableAttributes,
  SegmentOptions,
  SegmentSubscriberCount,
  SegmentSubscriberCountV2,
  SegmentUsage,
} from '../constants';
import { demoDataProvider, demoSegments } from '../utils';
import { SegmentInUseError, SegmentUsageServicesUnavailableError } from '../utils/errors';

const handleError = (response: Response<unknown>) => {
  if (!response.bodyText || typeof response.bodyText !== 'string') {
    throw new Error('Unexpected server error');
  } else {
    throw new Error(response.bodyText);
  }
};

export type FetchSegmentResponse = {
  segment: Segment;
  status: number;
};

export const fetchSegment = async (
  segmentId: number,
  useDemoData: boolean
): Promise<FetchSegmentResponse> => {
  if (useDemoData) {
    const demoSegment = demoDataProvider.getSegmentById(segmentId);
    const segment = demoSegment || demoSegments[0];
    return { segment, status: 200 };
  }

  const controller = new AbortController();
  // GMRU: GET /user-profile/v1/segments/{segmentId}
  const promise = API.get<Segment>(`/user-profile/v1/segments/${segmentId}`, null, {
    signal: controller.signal,
  }).then((response) => {
    if (response.status === 500) {
      throw new Error('Unexpected server error');
    } else if (!response.body && response.status !== 404) {
      throw new Error('Unexpected empty response from server');
    }
    return { segment: response.body as Segment, status: response.status };
  });
  // @ts-ignore
  promise.cancel = controller.abort;
  return promise;
};

export const fetchSegmentOptions = async () => {
  // GMRU: GET /user-profile/v1/segment-options
  const response = await API.get<SegmentOptions>('/user-profile/v1/segment-options');
  return response;
};

export const fetchLocationData = async (
  itemId: string,
  itemIdType: LocationType,
  locationType: LocationType
) => {
  const idQueryParam = itemIdType === LocationType.COUNTRY ? 'countryId' : 'stateId';
  // GMRU: GET /user-profile/v1/segment-options/location
  const response = await API.get<NumberValueDisplayableOption[]>(
    `/user-profile/v1/segment-options/location?${idQueryParam}=${itemId}&category=${locationType}`
  );
  return response;
};

export const fetchSegmentableAttributesByVendor = async () => {
  // GMRU: GET /user-profile/v1/segment-options/segmentable-attributes
  const response = await API.get<{ segmentableAttributesByVendor: SegmentableAttributes }>(
    '/user-profile/v1/segment-options/segmentable-attributes'
  );
  return response;
};

export async function createSegment(segment: BlankSegment) {
  // GMRU: POST /user-profile/v1/segments
  const response = await API.post<Segment>('/user-profile/v1/segments', segment);
  if (response.status !== 204 && response.status !== 200) {
    handleError(response);
  }
  return response;
}

export async function updateSegment(segment: Segment) {
  // GMRU: PUT /user-profile/v1/segments/{segmentId}
  const response = await API.put<Segment>(`/user-profile/v1/segments/${segment.id}`, segment);
  if (response.status !== 204 && response.status !== 200) {
    handleError(response);
  }
  return response;
}

export async function saveOrUpdateSegment(segment: Segment | BlankSegment) {
  if (isSavedSegment(segment) && isSegment(segment)) {
    return updateSegment(segment);
  }
  return createSegment(segment);
}

export const fetchWorkingSegmentCount = async (segment: Segment | BlankSegment) => {
  // GMRU: POST /user-profile/v1/segment-count
  const response = await API.post<SegmentSubscriberCount>(
    '/user-profile/v1/segment-count',
    segment
  );
  return response;
};

export const fetchWorkingSegmentCountV2 = async (segment: Segment | BlankSegment) => {
  // GMRU: POST /user-profile/v2/segment-count
  const response = await API.post<SegmentSubscriberCountV2>(
    '/user-profile/v2/segment-count',
    segment
  );
  return response;
};

export const fetchCreatedSegmentCountV2 = async (
  segmentId: number,
  useDemoData: boolean
): Promise<SegmentSubscriberCountV2> => {
  if (useDemoData) {
    return new Promise((res) => res(demoDataProvider.getSegmentCountByIdV2(segmentId)));
  }
  const controller = new AbortController();

  // GMRU: GET /user-profile/v2/segment-count/{segmentId}
  const promise = API.get<SegmentSubscriberCountV2>(
    `/user-profile/v2/segment-count/${segmentId}`,
    null,
    { signal: controller.signal }
  ).then((response) => {
    if (response.status !== 200) {
      throw new Error('Unexpected server error');
    } else if (!response.body) {
      throw new Error('Unexpected empty response from server');
    }
    return response.body as SegmentSubscriberCountV2;
  });
  // @ts-ignore
  promise.cancel = controller.abort;
  return promise;
};

export async function deleteSegmentById(segmentId: string, useDemoData: boolean) {
  if (useDemoData) {
    return Number(segmentId);
  }
  const response = await API.fetch(`/user-profile/v1/segments/${segmentId}`, {
    method: 'DELETE',
  });
  if (response.status === 409) {
    const { details } = response.body as { details: SegmentUsage };
    throw new SegmentInUseError(details);
  }
  if (response.status === 500) {
    throw new SegmentUsageServicesUnavailableError();
  }
  if (response.status !== 204 && response.status !== 200) {
    handleError(response);
  }
  return Number(segmentId);
}
