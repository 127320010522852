import React, { useEffect } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Box, Heading, Text } from '@attentive/picnic';

import { useLiveRegionUpdaters } from '../SegmentCreateEditModal/SegmentBuilderLiveArea';
import { getConditionDetails } from '../SegmentCreateEditModal/conditions';

import { DetailPaneComponentProps } from './SearchableDetailSelect';

const DetailPane: React.VFC<DetailPaneComponentProps> = ({ selectedItem, country }) => {
  const flags = { ENABLE_SEGMENT_ON_REGION: useCompanyFeatureFlag('ENABLE_SEGMENT_ON_REGION') };
  const { setLiveRegionText, clearLiveRegionText } = useLiveRegionUpdaters();
  const conditionDetails = selectedItem && getConditionDetails(selectedItem, country, flags);

  useEffect(() => {
    if (conditionDetails) {
      const liveRegionContent = `
        ${conditionDetails.label}.
        ${conditionDetails.source && `Source: ${conditionDetails.source.join(', ')}.`}
        ${conditionDetails.description}.
      `;

      setLiveRegionText(liveRegionContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionDetails]);

  useEffect(() => {
    // Clear the live region text when this component is unmounted
    return () => {
      clearLiveRegionText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      css={{
        flexGrow: 0,
        width: '250px',
        borderLeft: '$borderWidths$borderWidth1 solid $borderDefault',
        padding: '$space4 $space3',
        overflowWrap: 'break-word',
      }}
      data-testid="detail-pane"
    >
      {conditionDetails && (
        <>
          <Heading variant="md">{conditionDetails.label}</Heading>
          {conditionDetails.source && (
            <Text variant="caption" color="subdued" css={{ mt: '$space2' }}>
              Source: {conditionDetails.source.join(', ')}
            </Text>
          )}
          <Text css={{ mt: '$space4' }}>{conditionDetails.description}</Text>
        </>
      )}
    </Box>
  );
};

export default DetailPane;
