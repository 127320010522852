import { CompanyFeatureFlagNames } from '@attentive/acore-utils';
import { getLabelText, MenuItem } from '@attentive/picnic';

import {
  ActionFilter,
  EmailActionFilter,
  JoinedActionFilter,
  KnownKlaviyoProfileAttr,
  KnownSegmentIoFilterAttrs,
  MParticleFilterAttrs,
  PropertiesFilter,
  PunchhFilterAttrs,
  RechargeNamespacedFilterAttrs,
  ShopifyActionFilter,
  ShopifyFilter,
  SignUpMethodFilter,
  SubscriberPreference,
  SupportedCountryValues,
  TextActionFilter,
  UserPropertyType,
  Vendor,
  WellKnownPropertyTypes,
  SmileFilterAttrs,
  YotpoFilterAttrs,
  WellKnownPropertyLocaleTypes,
  UIAction,
} from '../../constants';

import { conditionCategories, ConditionSource } from './constants';
import { getCustomEventSource } from './utils/vendor';

interface BaseConditionDetails {
  label: string;
  category: string;
  description: string;
  source?: ConditionSource[];
}

interface ConditionDetails extends BaseConditionDetails {
  flagOverride?: { flagName: CompanyFeatureFlagNames; overrides: Partial<BaseConditionDetails> };
}

interface LocaleConditionDetails {
  [key: string]: { default: ConditionDetails; CA?: ConditionDetails; US?: ConditionDetails };
}

// TODO: sc - this currently only considers country but does not consider the users language. At some point we'll need to support French Canadian, for example
export const conditionDetails: LocaleConditionDetails = {
  ['ui:switch-to-activities' as UIAction]: {
    default: {
      label: 'Switch to activities',
      category: conditionCategories.UI,
      description: 'Switch to create segments based on subscribers’ activities.',
    },
  },
  ['ui:switch-to-characteristics' as UIAction]: {
    default: {
      label: 'Switch to characteristics',
      category: conditionCategories.UI,
      description: 'Switch to create segments based on subscribers’ characteristics.',
    },
  },
  [JoinedActionFilter.TEXT]: {
    default: {
      label: 'Subscribed to text',
      category: conditionCategories.TEXT,
      description: 'When a subscriber signed up to receive text messages',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [TextActionFilter.CLICKED]: {
    default: {
      label: 'Clicked a shortlink',
      category: conditionCategories.TEXT,
      description: 'The number of times a subscriber has clicked a link in a message',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [TextActionFilter.SENT_MESSAGE]: {
    default: {
      label: 'Sent a reply text',
      category: conditionCategories.TEXT,
      description:
        'The number of times a subscriber replied to a text message, can be filtered by a specific keyword',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [ActionFilter.ADDED_TO_CART]: {
    default: {
      label: 'Added to cart',
      category: conditionCategories.SHOPPING,
      description: 'The number of times a subscriber has added a product to their cart',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [ActionFilter.PURCHASED]: {
    default: {
      label: 'Made a purchase',
      category: conditionCategories.SHOPPING,
      description: 'The number of times a subscriber has made a purchase',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [ActionFilter.VIEWED]: {
    default: {
      label: 'Viewed a product',
      category: conditionCategories.SHOPPING,
      description: 'The number of times a subscriber has viewed a product',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [ShopifyActionFilter.PURCHASED]: {
    default: {
      label: 'Order confirmed',
      category: conditionCategories.SHOPPING,
      description: 'The number of times a subscriber received an order confirmation',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyActionFilter.REFUND_SUCCEEDED]: {
    default: {
      label: 'Received a refund',
      category: conditionCategories.SHOPPING,
      description: 'The number of times a subscriber received a refund for an order',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyActionFilter.CHECKOUT_ABANDONED]: {
    default: {
      label: 'Abandoned checkout',
      category: conditionCategories.SHOPPING,
      description:
        'The number of times a subscriber starts the checkout process without completing their purchase',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ActionFilter.VISITED_SITE]: {
    default: {
      label: 'Visited site',
      category: conditionCategories.SHOPPING,
      description:
        'The number of times a subscriber has visited a URL on your website. RegEx strings used for matching.',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.DEVICE_TYPE]: {
    default: {
      label: 'Device Type',
      category: conditionCategories.DEVICE,
      description:
        'The type of device a subscriber visited your site with (example: mobile, desktop, tablet)',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.OPERATING_SYSTEM]: {
    default: {
      label: 'Operating system',
      category: conditionCategories.DEVICE,
      description:
        'The type of operating system a subscriber visited your site with (example: Android, Chrome OS, iOS)',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.STATE]: {
    default: {
      label: 'State',
      category: conditionCategories.LOCATION,
      description: 'The state a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
    CA: {
      label: 'Province',
      category: conditionCategories.LOCATION,
      description: 'The province a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.CITY]: {
    default: {
      label: 'City',
      category: conditionCategories.LOCATION,
      description: 'The city a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.LEGACY_POSTAL_CODE]: {
    default: {
      label: 'Zip code',
      category: conditionCategories.LOCATION,
      description: 'The zip code a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
    CA: {
      label: 'Postal Code',
      category: conditionCategories.LOCATION,
      description: 'The postal code a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.BULK_POSTAL_CODES]: {
    default: {
      label: 'Zip code',
      category: conditionCategories.LOCATION,
      description: 'The zip code a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
    CA: {
      label: 'Postal Code',
      category: conditionCategories.LOCATION,
      description: 'The postal code a subscriber lives in, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.DISTANCE]: {
    default: {
      label: 'Distance',
      category: conditionCategories.LOCATION,
      description:
        'The subscribers who live within the radius of a zip code, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
    CA: {
      label: 'Distance',
      category: conditionCategories.LOCATION,
      description:
        'The subscribers who live within the radius of a postal code, based on their IP address',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [PropertiesFilter.COUNTRY]: {
    default: {
      label: 'Country',
      category: conditionCategories.LOCATION,
      description:
        'The country in which a subscriber is located, based on their recent internet usage activity.',
      source: [ConditionSource.ATTENTIVE],
      flagOverride: {
        flagName: 'ENABLE_SEGMENT_ON_REGION',
        overrides: { label: 'Country, recent' },
      },
    },
  },
  [SignUpMethodFilter.TEXT]: {
    default: {
      label: 'Text sign-up method',
      category: conditionCategories.TEXT,
      description:
        'The way that a subscriber signed up for texts, such as a sign-up unit or keyword',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [SignUpMethodFilter.EMAIL]: {
    default: {
      label: 'Email sign-up method',
      category: conditionCategories.TEXT,
      description: 'The way that a subscriber signed up for email',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [JoinedActionFilter.EMAIL]: {
    default: {
      label: 'Subscribed to email',
      category: conditionCategories.EMAIL,
      description: 'When a subscriber signed up to receive email messages',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [TextActionFilter.SENT]: {
    default: {
      label: 'Received a text',
      category: conditionCategories.TEXT,
      description: 'The number of times a subscriber received a text',
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [EmailActionFilter.CLICKED]: {
    default: {
      label: 'Clicked an email',
      category: conditionCategories.EMAIL,
      description: 'The number of times a subscriber clicked a link from an email',
    },
  },
  [EmailActionFilter.OPENED]: {
    default: {
      label: 'Opened an email',
      category: conditionCategories.EMAIL,
      description: 'The number of times a subscriber opened an email',
    },
  },
  [EmailActionFilter.SENT]: {
    default: {
      label: 'Received an email',
      category: conditionCategories.EMAIL,
      description: 'The number of times a subscriber received an email',
    },
  },
  [ShopifyFilter.ACCEPTS_MARKETING]: {
    default: {
      label: 'Accepts marketing',
      category: conditionCategories.EMAIL,
      description: 'Whether the customer has agreed to receive marketing emails',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.ACCEPTS_MARKETING_UPDATED_TIME]: {
    default: {
      label: 'Opt-in date',
      category: conditionCategories.EMAIL,
      description: 'The date and time when the customer agreed to receive marketing emails',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.MARKETING_OPT_IN_LEVEL]: {
    default: {
      label: 'Opt-in level',
      category: conditionCategories.EMAIL,
      description:
        'The opt-in level the customer gave when they agreed to receive marketing emails',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.CURRENCY]: {
    default: {
      label: 'Currency',
      category: conditionCategories.SHOPPING,
      description: 'The type of currency a customer used to make a purchase',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.CUSTOMER_STATE]: {
    default: {
      label: 'Customer account status',
      category: conditionCategories.SHOPPING,
      description:
        'The status of the customer’s account with a shop. This is usually tied to membership and loyalty programs.',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.CUSTOMER_TAG]: {
    default: {
      label: 'Tag',
      category: conditionCategories.SHOPPING,
      description: 'The tags that the shop owner has assigned to the customer',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.LIFETIME_SPEND]: {
    default: {
      label: 'Lifetime spend',
      category: conditionCategories.SHOPPING,
      description: 'The total amount of money the customer has spent',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.ORDERS_COUNT]: {
    default: {
      label: 'Number of orders',
      category: conditionCategories.SHOPPING,
      description: 'The number of orders the customer has made',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.CREATED_TIME]: {
    default: {
      label: 'Date added',
      category: conditionCategories.SHOPPING,
      description: 'The date and time when a customer record was created',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [ShopifyFilter.UPDATED_TIME]: {
    default: {
      label: 'Date updated',
      category: conditionCategories.SHOPPING,
      description: 'The date and time when an existing customer record was updated',
      source: [ConditionSource.SHOPIFY],
    },
  },
  [KnownKlaviyoProfileAttr.CITY]: {
    default: {
      label: 'City',
      category: conditionCategories.PROFILE,
      description: 'The city a subscriber lives in',
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.COUNTRY]: {
    default: {
      label: 'Country',
      category: conditionCategories.PROFILE,
      description: 'The country a subscriber lives in',
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.EMAIL]: {
    default: {
      label: 'Email',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains an email address",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.FIRST_NAME]: {
    default: {
      label: 'First name',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains a first name",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.LAST_NAME]: {
    default: {
      label: 'Last name',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains a last name",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.ORGANIZATION]: {
    default: {
      label: 'Organization',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains an organization",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.PHONE_NUMBER]: {
    default: {
      label: 'Phone number',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains a phone number",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.PHONE_NUMBER_REGION]: {
    default: {
      label: 'Phone number region',
      category: conditionCategories.PROFILE,
      description: "The region associated with the subscriber's phone number",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.REGION]: {
    default: {
      label: 'Region',
      category: conditionCategories.PROFILE,
      description: 'The region a subscriber lives in',
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.TIMEZONE]: {
    default: {
      label: 'Timezone',
      category: conditionCategories.PROFILE,
      description: "The timezone associated with a subscriber's location",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.ZIP]: {
    default: {
      label: 'Zip code',
      category: conditionCategories.PROFILE,
      description: "Whether or not the subscriber's profile contains a zip code",
      source: [ConditionSource.KLAVIYO],
    },
  },
  [KnownKlaviyoProfileAttr.CREATED]: {
    default: {
      label: 'Opt-in date',
      category: conditionCategories.PROFILE,
      description: 'The date and time when the customer agreed to receive marketing emails',
      source: [ConditionSource.KLAVIYO],
    },
  },
  [YotpoFilterAttrs.BIRTHDAY_MONTH]: {
    default: {
      label: 'Birthday month',
      category: conditionCategories.YOTPO,
      description: 'The calendar month of the subscribers’ birthday',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.BIRTHDAY_YEAR]: {
    default: {
      label: 'Birthday year',
      category: conditionCategories.YOTPO,
      description: 'The year of the subscribers’ birthday',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.LAST_PURCHASED_AT]: {
    default: {
      label: 'Last purchase at',
      category: conditionCategories.YOTPO,
      description: 'Last date a subscriber made a purchase',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.OPTED_IN_AT]: {
    default: {
      label: 'Opted in at',
      category: conditionCategories.YOTPO,
      description: 'The date the subscriber opted in',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.PERKS_REDEEMED]: {
    default: {
      label: 'Perks redeemed',
      category: conditionCategories.YOTPO,
      description: 'Total number of perks redeemed',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.POINTS_BALANCE]: {
    default: {
      label: 'Points balance',
      category: conditionCategories.YOTPO,
      description: 'Total current reward point balance',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.POINTS_EXPIRE_AT]: {
    default: {
      label: 'Points expire at',
      category: conditionCategories.YOTPO,
      description: 'Date at which points expire',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.TOTAL_PURCHASES]: {
    default: {
      label: 'Total purchases',
      category: conditionCategories.YOTPO,
      description: 'Total purchases made by a subscriber',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.TOTAL_SPEND_CENTS]: {
    default: {
      label: 'Total spend cents',
      category: conditionCategories.YOTPO,
      description: 'Total spend of a subscriber in dollars',
      source: [ConditionSource.YOTPO],
    },
  },
  [YotpoFilterAttrs.VIP_TIER_NAME]: {
    default: {
      label: 'Vip tier name',
      category: conditionCategories.YOTPO,
      description: '',
      source: [ConditionSource.YOTPO],
    },
  },
  [KnownSegmentIoFilterAttrs.AGE]: {
    default: {
      label: 'Age',
      category: conditionCategories.SEGMENT_IO,
      description: 'The age of the subscriber',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [KnownSegmentIoFilterAttrs.BIRTHDAY]: {
    default: {
      label: 'Birthday',
      category: conditionCategories.SEGMENT_IO,
      description: 'Birthday of the subscriber',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [KnownSegmentIoFilterAttrs.CREATED_AT]: {
    default: {
      label: 'Created at',
      category: conditionCategories.SEGMENT_IO,
      description: 'Date the profile of the subscriber was created',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [KnownSegmentIoFilterAttrs.GENDER]: {
    default: {
      label: 'Gender',
      category: conditionCategories.SEGMENT_IO,
      description: 'Gender of the subscriber',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [KnownSegmentIoFilterAttrs.LOGINS]: {
    default: {
      label: 'Logins',
      category: conditionCategories.SEGMENT_IO,
      description: '',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [KnownSegmentIoFilterAttrs.PLAN]: {
    default: {
      label: 'Plan',
      category: conditionCategories.SEGMENT_IO,
      description: '',
      source: [ConditionSource.SEGMENT_IO],
    },
  },
  [PunchhFilterAttrs.AGE_VERIFIED]: {
    default: {
      label: 'Age verified',
      category: conditionCategories.PUNCHH,
      description: 'Subscribers who are age verified',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.ANNIVERSARY]: {
    default: {
      label: 'Anniversary',
      category: conditionCategories.PUNCHH,
      description: 'Anniversary of the subscriber with the company or brand',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.BANNED]: {
    default: {
      label: 'Banned',
      category: conditionCategories.PUNCHH,
      description: 'Banned by account administrator',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.BIRTHDAY]: {
    default: {
      label: 'Birthday',
      category: conditionCategories.PUNCHH,
      description: 'Birthday of the subscriber',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.CHECKINS]: {
    default: {
      label: 'Checkins',
      category: conditionCategories.PUNCHH,
      description: 'Last check-in location name',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.CITY]: {
    default: {
      label: 'City',
      category: conditionCategories.PUNCHH,
      description: 'City the subscriber resides in',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.FAVOURITE_LOCATIONS]: {
    default: {
      label: 'Favourite locations',
      category: conditionCategories.PUNCHH,
      description: 'Subscribers favorite locations',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.GENDER]: {
    default: {
      label: 'Gender',
      category: conditionCategories.PUNCHH,
      description: 'Gender association of the subscriber',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.LAST_ACTIVITY_AT]: {
    default: {
      label: 'Last activity at',
      category: conditionCategories.PUNCHH,
      description: 'Last known activity timestamp for the subscriber',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.LAST_SIGN_IN_AT]: {
    default: {
      label: 'Last sign in at',
      category: conditionCategories.PUNCHH,
      description: 'Last known sign-in for the subscriber',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.MARKETING_EMAIL_SUBSCRIPTION]: {
    default: {
      label: 'Marketing email subscription',
      category: conditionCategories.PUNCHH,
      description: 'Whether the subscriber is signed up for marketing emails',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.MARKETING_PN_SUBSCRIPTION]: {
    default: {
      label: 'Marketing push notification subscription',
      category: conditionCategories.PUNCHH,
      description: 'Whether the subscriber is signed up for marketing push-notifications',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.PREFERRED_LOCALE]: {
    default: {
      label: 'Preferred locale',
      category: conditionCategories.PUNCHH,
      description: 'Preferred location set by the subscriber',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.SIGNUP_CHANNEL]: {
    default: {
      label: 'Signup channel',
      category: conditionCategories.PUNCHH,
      description: 'Channel that the subscriber signed up through',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.SMS_SUBSCRIPTION]: {
    default: {
      label: 'Sms subscription',
      category: conditionCategories.PUNCHH,
      description: 'Whether the subscriber is signed up for SMS services',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.STATE]: {
    default: {
      label: 'State',
      category: conditionCategories.PUNCHH,
      description: 'State the subscriber resides in',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.UNSUBSCRIBED]: {
    default: {
      label: 'Unsubscribed',
      category: conditionCategories.PUNCHH,
      description: 'Whether the user is subscribed for loyalty or not',
      source: [ConditionSource.PUNCHH],
    },
  },
  [PunchhFilterAttrs.ZIP_CODE]: {
    default: {
      label: 'Zip code',
      category: conditionCategories.PUNCHH,
      description: 'Zip code the subscriber resides in',
      source: [ConditionSource.PUNCHH],
    },
  },
  [MParticleFilterAttrs.AGE]: {
    default: {
      label: 'Age',
      category: conditionCategories.MPARTICLE,
      description: 'The age of the subscriber',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [MParticleFilterAttrs.CITY]: {
    default: {
      label: 'City',
      category: conditionCategories.MPARTICLE,
      description: 'City the subscriber resides in',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [MParticleFilterAttrs.COUNTRY]: {
    default: {
      label: 'Country',
      category: conditionCategories.MPARTICLE,
      description: 'Country the subscriber resides in',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [MParticleFilterAttrs.GENDER]: {
    default: {
      label: 'Gender',
      category: conditionCategories.MPARTICLE,
      description: 'The gender of the subscriber',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [MParticleFilterAttrs.STATE]: {
    default: {
      label: 'State',
      category: conditionCategories.MPARTICLE,
      description: 'State the subscriber resides in',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [MParticleFilterAttrs.ZIP]: {
    default: {
      label: 'Zip code',
      category: conditionCategories.MPARTICLE,
      description: 'Zip code the subscriber resides in',
      source: [ConditionSource.MPARTICLE],
    },
  },
  [SmileFilterAttrs.BIRTH_MONTH]: {
    default: {
      label: 'Birth month',
      category: conditionCategories.SMILE,
      description: "Month of the subscriber's birthday",
      source: [ConditionSource.SMILE],
    },
  },
  [SmileFilterAttrs.POINTS_BALANCE]: {
    default: {
      label: 'Points balance',
      category: conditionCategories.SMILE,
      description: 'Smile points balance',
      source: [ConditionSource.SMILE],
    },
  },
  [SmileFilterAttrs.REFERRAL_URL]: {
    default: {
      label: 'Referral URL',
      category: conditionCategories.SMILE,
      description: 'Smile referral URL',
      source: [ConditionSource.SMILE],
    },
  },
  [SmileFilterAttrs.STATE]: {
    default: {
      label: 'State',
      category: conditionCategories.SMILE,
      description: 'Smile state',
      source: [ConditionSource.SMILE],
    },
  },
  [SmileFilterAttrs.VIP_TIER_ID]: {
    default: {
      label: 'VIP tier ID',
      category: conditionCategories.SMILE,
      description: 'Smile VIP tier ID',
      source: [ConditionSource.SMILE],
    },
  },
  [SmileFilterAttrs.VIP_TIER_NAME]: {
    default: {
      label: 'VIP tier name',
      category: conditionCategories.SMILE,
      description: 'Smile VIP tier name',
      source: [ConditionSource.SMILE],
    },
  },
  [RechargeNamespacedFilterAttrs.SUBSCRIPTION_STATUS]: {
    default: {
      label: 'Subscription status',
      category: conditionCategories.RECHARGE,
      description: `The status of a subscriber's subscription`,
      source: [ConditionSource.RECHARGE],
    },
  },
  [RechargeNamespacedFilterAttrs.SUBSCRIPTION_ACTIVATION_DATE]: {
    default: {
      label: 'Subscription activation date',
      category: conditionCategories.RECHARGE,
      description: `When a subscriber's subscription was activated`,
      source: [ConditionSource.RECHARGE],
    },
  },
  [RechargeNamespacedFilterAttrs.SUBSCRIPTION_UPCOMING_CHARGE_DATE]: {
    default: {
      label: 'Subscription upcoming charge date',
      category: conditionCategories.RECHARGE,
      description: 'When a subscriber will next be charged for their subscription',
      source: [ConditionSource.RECHARGE],
    },
  },
  [RechargeNamespacedFilterAttrs.SUBSCRIPTION_CANCELLATION_DATE]: {
    default: {
      label: 'Subscription cancellation date',
      category: conditionCategories.RECHARGE,
      description: `When a subscriber's subscription is canceled`,
      source: [ConditionSource.RECHARGE],
    },
  },
  [ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID]: {
    default: {
      label: 'Subscription charged',
      category: conditionCategories.RECHARGE,
      description: 'Subscription charges made to the subscriber',
      source: [ConditionSource.RECHARGE],
    },
  },
  [ActionFilter.ORDER_SUBSCRIPTION_PROCESSED]: {
    default: {
      label: 'Order processed',
      category: conditionCategories.RECHARGE,
      description: 'Subscription orders fulfilled for a subscriber',
      source: [ConditionSource.RECHARGE],
    },
  },
  [ActionFilter.COMPLETED_REVIEW]: {
    default: {
      label: 'Completed a review',
      category: conditionCategories.BAZAARVOICE,
      description:
        'The number of times and recency that a subscriber has completed a review of a specific product or product in a specific category.',
      source: [ConditionSource.BAZAARVOICE],
    },
  },
  [ActionFilter.LEFT_A_RATING]: {
    default: {
      label: 'Review rating',
      category: conditionCategories.BAZAARVOICE,
      description: 'The star rating a subscriber has given a product.',
      source: [ConditionSource.BAZAARVOICE],
    },
  },
  [`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.KLAVIYO_LAST_EMAIL_OPEN}`]: {
    default: {
      label: 'Klaviyo email last opened',
      category: conditionCategories.EMAIL,
      description: 'The date of the last email open for a subscriber',
      source: [ConditionSource.KLAVIYO],
    },
  },
  [`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.MAILCHIMP_MEMBER_RATING}`]: {
    default: {
      label: 'Mailchimp Member Rating',
      category: conditionCategories.PROFILE,
      description: `The subscriber's Mailchimp member rating`,
      source: [ConditionSource.MAILCHIMP],
    },
  },
  [`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.MAIL_EXCHANGE}`]: {
    default: {
      label: 'Mailbox provider',
      category: conditionCategories.EMAIL,
      description: `The mailbox provider or exchange that a subscriber has signed up with`,
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.COUNTRY}`]: {
    default: {
      label: 'Country, residence',
      category: conditionCategories.LOCATION,
      description: `The country in which a subscriber is located, based on their subscription details`,
      source: [ConditionSource.ATTENTIVE],
    },
  },
  [`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.LOCALE}:${WellKnownPropertyLocaleTypes.LANGUAGE}`]:
    {
      default: {
        label: 'Language',
        category: conditionCategories.LOCATION,
        description: 'The preferred language of the subscriber based on their entry sign-up unit',
        source: [ConditionSource.ATTENTIVE],
      },
    },
};

export const getConditionDetails = (
  item: MenuItem,
  country: SupportedCountryValues = 'US',
  enabledFlags?: Partial<Record<CompanyFeatureFlagNames, boolean>>
) => {
  if (!item || typeof item.value !== 'string') return null;
  const { value, label } = item;

  if (conditionDetails[value]) {
    const selectedCondition = conditionDetails[value][country] || conditionDetails[value].default;

    if (!selectedCondition.flagOverride || !enabledFlags) {
      return selectedCondition;
    }

    const flagIsEnabled = enabledFlags[selectedCondition.flagOverride.flagName];

    if (flagIsEnabled) {
      return {
        ...selectedCondition,
        ...selectedCondition.flagOverride.overrides,
      };
    }

    return selectedCondition;
  }

  if (value.startsWith(UserPropertyType.CUSTOM_EVENT_TYPE)) {
    return { label: getLabelText(label), source: getCustomEventSource(item), description: '' };
  }

  if (value.startsWith(SubscriberPreference.SUBSCRIBER_PREFERENCES)) {
    return { label: getLabelText(label), source: [ConditionSource.ATTENTIVE], description: '' };
  }

  if (value.startsWith(UserPropertyType.CUSTOM_PROPERTY_ID)) {
    return { label: getLabelText(label), source: [ConditionSource.ATTENTIVE], description: '' };
  }

  if (value.startsWith(Vendor.PROFILE_VENDOR_KLAVIYO)) {
    return { label: getLabelText(label), source: [ConditionSource.KLAVIYO], description: '' };
  }

  if (value.startsWith(Vendor.SEGMENT_IO)) {
    return { label: getLabelText(label), source: [ConditionSource.SEGMENT_IO], description: '' };
  }

  if (value.startsWith(Vendor.PUNCHH)) {
    return { label: getLabelText(label), source: [ConditionSource.PUNCHH], description: '' };
  }

  if (value.startsWith(Vendor.YOTPO)) {
    return { label: getLabelText(label), source: [ConditionSource.YOTPO], description: '' };
  }

  if (value.startsWith(Vendor.MPARTICLE)) {
    return { label: getLabelText(label), source: [ConditionSource.MPARTICLE], description: '' };
  }

  if (value.startsWith(Vendor.SMILE)) {
    return { label: getLabelText(label), source: [ConditionSource.SMILE], description: '' };
  }

  if (value.startsWith(Vendor.RECHARGE)) {
    return { label: getLabelText(label), source: [ConditionSource.RECHARGE], description: '' };
  }

  return null;
};
