import _useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition from "./__generated__/useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition.graphql";
import _useCustomAttributes_TargetingCommon_Attributes_query from "./__generated__/useCustomAttributes_TargetingCommon_Attributes_query.graphql";
import { useEffect, useState } from 'react';
import { usePaginationFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { deserializeGQLDateTime, graphql, readInlineData } from '@attentive/data';
import { getTypeLabel } from '../CustomAttributeDialog/components/utils';
import { ATTRIBUTE_INPUT_TYPE_LABELS, isSupportedType, PROPERTY_TYPE_TO_INPUT_MAP } from '../CustomAttributeDialog/constants';
import AttributeFragment from './__generated__/useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition.graphql';
import AttributesQuery from './__generated__/useCustomAttributes_TargetingCommon_Attributes_query.graphql';
_useCustomAttributes_TargetingCommon_Attributes_query;
export const useCustomAttributes = function (queryRef) {
  let itemsPerPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  let customPropertyTypeFilter = arguments.length > 2 ? arguments[2] : undefined;
  const enableLongDataType = useCompanyFeatureFlag('ENABLE_CUSTOM_ATTRIBUTES_LONG_TYPE');
  const [offset, setOffset] = useState(0);
  const [allAttributes, setAllAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const {
    data,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(AttributesQuery, queryRef);
  const currentOffset = offset * itemsPerPage;
  useEffect(() => {
    if (!hasNext) {
      var _data$company, _data$company$userPro;

      const attributes = ((_data$company = data.company) === null || _data$company === void 0 ? void 0 : (_data$company$userPro = _data$company.userPropertyDefinitionsV2) === null || _data$company$userPro === void 0 ? void 0 : _data$company$userPro.edges) || [];
      const formatted = attributes.map(attributeRef => formatAttribute(attributeRef.node, customPropertyTypeFilter, enableLongDataType)).filter(attr => attr !== null);
      setAllAttributes(formatted);
      setFilteredAttributes(formatted);
    } else {
      loadNext(100);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [hasNext, data, isLoadingNext]);

  const searchAttributes = _searchValue => {
    setOffset(0);

    if (!_searchValue) {
      setFilteredAttributes(allAttributes);
      return;
    }

    const searchValue = _searchValue.toLowerCase().trim();

    const filteredValues = allAttributes.filter(attribute => {
      const name = attribute.name.toLowerCase();
      return name.includes(searchValue);
    });
    setFilteredAttributes(filteredValues);
  };

  return {
    allAttributes,
    attributes: filteredAttributes.slice(currentOffset, currentOffset + itemsPerPage),
    isFetching: hasNext || isLoadingNext,
    searchAttributes,
    offset,
    refetch,
    setOffset,
    totalItems: filteredAttributes.length
  };
};
_useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition;

function formatAttribute(attributeRef, customPropertyTypeFilter, isLongDataTypeEnabled) {
  const attribute = readInlineData(AttributeFragment, attributeRef);
  const {
    id,
    created,
    name,
    propertyType,
    customPropertyId
  } = attribute;
  if (!isSupportedType(propertyType)) return null;
  if (customPropertyTypeFilter && propertyType !== customPropertyTypeFilter) return null;
  const inputType = PROPERTY_TYPE_TO_INPUT_MAP[propertyType];
  return {
    id,
    created: deserializeGQLDateTime(created),
    name,
    type: ATTRIBUTE_INPUT_TYPE_LABELS[inputType],
    dataType: getTypeLabel(propertyType, isLongDataTypeEnabled),
    internalId: customPropertyId.id,
    queryRef: attribute
  };
}