import React from 'react';

import { useCompanyFeatureFlag, usePermission } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';
import { Select } from '@attentive/picnic';

import {
  ATTRIBUTE_INPUT_TYPES,
  SupportedPropertyType,
  SupportedPropertyTypeWithEmpty,
  typeOptions,
} from '../constants';

import { getTypeLabel } from './utils';

interface DataTypeSelectProps {
  isEditing?: boolean;
  inputTypeValue: ATTRIBUTE_INPUT_TYPES;
  dataTypeValue: SupportedPropertyTypeWithEmpty;
  onChange: (value: SupportedPropertyType) => void;
  size: 'small' | 'normal';
}

export function DataTypeSelect({
  dataTypeValue,
  inputTypeValue,
  isEditing,
  onChange,
  size,
}: DataTypeSelectProps) {
  const enableNumber = useCompanyFeatureFlag(
    'ENABLE_CUSTOM_ATTRIBUTE_DATE_AND_NUMBER_TYPE_CREATION'
  );
  const enableLongDataType = useCompanyFeatureFlag('ENABLE_CUSTOM_ATTRIBUTES_LONG_TYPE');
  const options = getTypeOptions(inputTypeValue, enableLongDataType);
  const isSuperUser = usePermission(Permission.SuperUserAccess);

  const isFieldDisabled = isDisabled({
    inputType: inputTypeValue,
    enableNumber,
    isEditing,
    isSuperUser,
  });

  return (
    <Select
      disabled={isFieldDisabled}
      value={dataTypeValue}
      placeholder="Select a data type"
      name="dataType"
      size={size === 'normal' ? 'medium' : size}
      onChange={(value: string) => onChange(value as SupportedPropertyType)}
    >
      {options.map((option) => {
        return (
          <Select.Item key={option} value={option}>
            {getTypeLabel(option as SupportedPropertyType, enableLongDataType)}
          </Select.Item>
        );
      })}
    </Select>
  );
}

function getTypeOptions(inputTypeValue: ATTRIBUTE_INPUT_TYPES, isLongDataTypeEnabled: boolean) {
  // We only have to filter the type options if it's short answer and long data type is *NOT* enabled
  if (inputTypeValue !== ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER || isLongDataTypeEnabled) {
    return typeOptions[inputTypeValue];
  }

  return typeOptions[inputTypeValue].filter((item) => item !== 'TYPE_LONG');
}

function isDisabled({
  inputType,
  enableNumber,
  isEditing,
  isSuperUser,
}: {
  inputType: ATTRIBUTE_INPUT_TYPES;
  enableNumber: boolean;
  isEditing?: boolean;
  isSuperUser: boolean;
}) {
  // Short answer is the only input type that supports multiple data types
  if (inputType !== ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER) {
    return true;
  }

  // If the FF for creating numbers is enabled, enable the field
  if (enableNumber) {
    return false;
  }

  // Super users can edit existing attributes and change them to number types
  return !(isEditing && isSuperUser);
}
