import _useGetSegmentCountMetric_TargetingCommon_Segment from "./__generated__/useGetSegmentCountMetric_TargetingCommon_Segment.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { getFormattedSegmentCountMetric } from '../utils';
import SegmentFragment from './__generated__/useGetSegmentCountMetric_TargetingCommon_Segment.graphql';
_useGetSegmentCountMetric_TargetingCommon_Segment;
const noSubsFallback = {
  activeSubscribers: 0,
  activeSubscribersNotFatigued: 0
}; // if metric is undefined, returns total segment count

export const useGetSegmentCountMetric = _ref => {
  let {
    segmentRef,
    metric
  } = _ref;
  const segmentData = useFragment(SegmentFragment, segmentRef);
  let channelMetrics;

  if (metric) {
    var _segmentData$segmentC;

    channelMetrics = (segmentData === null || segmentData === void 0 ? void 0 : (_segmentData$segmentC = segmentData.segmentCount) === null || _segmentData$segmentC === void 0 ? void 0 : _segmentData$segmentC[metric]) || noSubsFallback;
  } else {
    var _segmentData$segmentC2, _segmentData$segmentC3, _segmentData$segmentC4;

    const smsSubs = (segmentData === null || segmentData === void 0 ? void 0 : (_segmentData$segmentC2 = segmentData.segmentCount) === null || _segmentData$segmentC2 === void 0 ? void 0 : _segmentData$segmentC2.sms) || noSubsFallback;
    const emailSubs = (segmentData === null || segmentData === void 0 ? void 0 : (_segmentData$segmentC3 = segmentData.segmentCount) === null || _segmentData$segmentC3 === void 0 ? void 0 : _segmentData$segmentC3.email) || noSubsFallback;
    const multiChannelSubs = (segmentData === null || segmentData === void 0 ? void 0 : (_segmentData$segmentC4 = segmentData.segmentCount) === null || _segmentData$segmentC4 === void 0 ? void 0 : _segmentData$segmentC4.smsAndEmail) || noSubsFallback;
    channelMetrics = {
      activeSubscribers: smsSubs.activeSubscribers + emailSubs.activeSubscribers - multiChannelSubs.activeSubscribers,
      activeSubscribersNotFatigued: smsSubs.activeSubscribersNotFatigued + emailSubs.activeSubscribersNotFatigued - multiChannelSubs.activeSubscribersNotFatigued
    };
  }

  return {
    activeSubscribers: getFormattedSegmentCountMetric(channelMetrics.activeSubscribers),
    activeSubscribersNotFatigued: getFormattedSegmentCountMetric(channelMetrics.activeSubscribersNotFatigued)
  };
};