import { CustomPropertyType } from './components/ExistingCustomAttributeDialog/__generated__/formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition.graphql';

export enum ATTRIBUTE_INPUT_TYPES {
  SHORT_ANSWER = 'shortAnswer',
  DATE = 'date',
  SINGLE_SELECT = 'singleSelect',
  MUTLI_SELECT = 'multiSelect',
  BOOLEAN = 'boolean',
  CHECKBOX = 'checkbox',
  EMPTY = '',
}

const TYPE_BOOL: CustomPropertyType = 'TYPE_BOOL';
const TYPE_DATE: CustomPropertyType = 'TYPE_DATE';
const TYPE_ENUM: CustomPropertyType = 'TYPE_ENUM';
const TYPE_LONG: CustomPropertyType = 'TYPE_LONG';
const TYPE_NUMBER: CustomPropertyType = 'TYPE_NUMBER';
const TYPE_STRING: CustomPropertyType = 'TYPE_STRING';

export const supportedPropertyTypes = [
  'TYPE_BOOL',
  'TYPE_DATE',
  'TYPE_ENUM',
  'TYPE_LONG',
  'TYPE_NUMBER',
  'TYPE_STRING',
] as const;
type SupportedPropertyTypeTuple = typeof supportedPropertyTypes;
export type SupportedPropertyType = SupportedPropertyTypeTuple[number];
export type SupportedPropertyTypeWithEmpty = SupportedPropertyType | '';

export function isSupportedType(value: string): value is SupportedPropertyType {
  return supportedPropertyTypes.includes(value as SupportedPropertyType);
}

// Maps the data type to the correct input type
export const PROPERTY_TYPE_TO_INPUT_MAP = {
  [TYPE_BOOL]: ATTRIBUTE_INPUT_TYPES.BOOLEAN,
  [TYPE_DATE]: ATTRIBUTE_INPUT_TYPES.DATE,
  [TYPE_ENUM]: ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT,
  [TYPE_LONG]: ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER,
  [TYPE_NUMBER]: ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER,
  [TYPE_STRING]: ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER,
};

export const ATTRIBUTE_INPUT_TYPE_LABELS = {
  [ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER]: 'Short answer',
  [ATTRIBUTE_INPUT_TYPES.DATE]: 'Date',
  [ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT]: 'Single select',
  [ATTRIBUTE_INPUT_TYPES.MUTLI_SELECT]: 'Multi-select',
  [ATTRIBUTE_INPUT_TYPES.BOOLEAN]: 'Boolean',
  [ATTRIBUTE_INPUT_TYPES.CHECKBOX]: 'Checkbox',
  [ATTRIBUTE_INPUT_TYPES.EMPTY]: 'Empty',
};

// Map the FE data type to the expected BE data type. The BE has no enum type and uses string
export const PROPERTY_TYPE_TO_DATA_TYPE_MAP = {
  [TYPE_BOOL]: TYPE_BOOL,
  [TYPE_DATE]: TYPE_DATE,
  [TYPE_ENUM]: TYPE_STRING,
  [TYPE_LONG]: TYPE_LONG,
  [TYPE_NUMBER]: TYPE_NUMBER,
  [TYPE_STRING]: TYPE_STRING,
};

export const typeLabels = {
  [TYPE_BOOL]: 'Boolean',
  [TYPE_DATE]: 'Date',
  [TYPE_ENUM]: 'Enum',
  [TYPE_LONG]: 'Number',
  [TYPE_NUMBER]: 'Decimal',
  [TYPE_STRING]: 'Text string',
};

// Mapping of input types to possible data types. Only short answer supports multiple types
export const typeOptions: Record<ATTRIBUTE_INPUT_TYPES, CustomPropertyType[]> = {
  [ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER]: [TYPE_NUMBER, TYPE_LONG, TYPE_STRING],
  [ATTRIBUTE_INPUT_TYPES.DATE]: [TYPE_DATE],
  [ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT]: [TYPE_STRING],
  [ATTRIBUTE_INPUT_TYPES.MUTLI_SELECT]: [TYPE_STRING],
  [ATTRIBUTE_INPUT_TYPES.BOOLEAN]: [TYPE_BOOL],
  [ATTRIBUTE_INPUT_TYPES.CHECKBOX]: [TYPE_STRING],
  [ATTRIBUTE_INPUT_TYPES.EMPTY]: [TYPE_STRING],
};

// Mapping of input types to default data types.
export const typeDefaults = {
  [ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER]: TYPE_STRING,
  [ATTRIBUTE_INPUT_TYPES.DATE]: TYPE_DATE,
  [ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT]: TYPE_STRING,
  [ATTRIBUTE_INPUT_TYPES.MUTLI_SELECT]: TYPE_STRING,
  [ATTRIBUTE_INPUT_TYPES.BOOLEAN]: TYPE_BOOL,
  [ATTRIBUTE_INPUT_TYPES.CHECKBOX]: TYPE_STRING,
  [ATTRIBUTE_INPUT_TYPES.EMPTY]: TYPE_STRING,
};

export const enumInputTypes = [
  ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT,
  ATTRIBUTE_INPUT_TYPES.MUTLI_SELECT,
];

export enum CustomAttributeErrors {
  ALREADY_EXISTS = 'This custom attribute already exists. Please rename it.',
  UNKNOWN_CREATE_ERROR = 'Error creating custom attribute',
  UNKNOWN_UPDATE_ERROR = 'Error updating custom attribute',
}
