import { BlankSegment, Segment, SegmentComponent } from '../../../constants';

type HighlightConditionConfig =
  | {
      type: 'USER_PROPERTY';
      id: string;
    }
  | { channel: 'EMAIL' | 'TEXT'; type: 'CHANNEL' };

export type HighlightConditionConfigs = HighlightConditionConfig[];

export function isComponentHighlighted(
  component: SegmentComponent,
  highlightConditions?: HighlightConditionConfigs
) {
  if (!highlightConditions) return false;

  return highlightConditions.some((highlightCondition) =>
    testCondition(component, highlightCondition)
  );
}

export function isPreviousComponentHighlighted(
  segmentComponents: SegmentComponent[],
  currentIndex: number,
  highlightConditions?: HighlightConditionConfigs
) {
  if (!highlightConditions || currentIndex === 0) {
    return false;
  }

  const previousComponent = segmentComponents[currentIndex - 1];

  return isComponentHighlighted(previousComponent, highlightConditions);
}

export function hasHighlightedComponents(
  segment: Segment | BlankSegment,
  highlightConditions?: HighlightConditionConfigs
) {
  if (!highlightConditions || !segment.expressions) {
    return false;
  }

  return highlightConditions.some((highlightCondition) => {
    return segment.expressions.some((exp) => {
      return exp.segmentComponents.some((component) => {
        return testCondition(component, highlightCondition);
      });
    });
  });
}

function testCondition(
  component: SegmentComponent,
  highlightCondition: HighlightConditionConfig
): boolean {
  switch (highlightCondition.type) {
    case 'CHANNEL':
      return isMatchingChannel(component, highlightCondition);
    case 'USER_PROPERTY':
      return isMatchingUserProperty(component, highlightCondition);
    default:
      return false;
  }
}

function isMatchingUserProperty(
  component: SegmentComponent,
  highlightCondition: HighlightConditionConfig
) {
  return (
    highlightCondition.type === 'USER_PROPERTY' &&
    component.type === highlightCondition.type &&
    component.parameters.attribute === highlightCondition.id
  );
}

function isMatchingChannel(
  component: SegmentComponent,
  highlightCondition: HighlightConditionConfig
) {
  return (
    highlightCondition.type === 'CHANNEL' &&
    component.parameters.subscriberActionChannel === highlightCondition.channel
  );
}
