import React, { FC, useCallback, useMemo } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { CountryCode } from '@attentive/locale-utils';
import { Box, Separator } from '@attentive/picnic';

import {
  UserPropertyType,
  FilterValue,
  PropertiesFilter,
  SubscriberPreference,
  SegmentOptions,
  Vendor,
  SignUpMethodFilter,
  SupportedCountryValues,
  WellKnownPropertyTypes,
  SupportedCustomAttributeDataType,
  WellKnownPropertyLocaleTypes,
  SegmentableAttributes,
  UIAction,
  FilterType,
} from '../../../../constants';
import { getPostalCodeLabel, getStateLabel } from '../../../../utils';
import { useIsOperatorUIEnabled } from '../../../OperatorUIToggle';
import { SearchableDetailSelect } from '../../../SearchableDetailSelect';
import DetailPane from '../../../SearchableDetailSelect/DetailPane';
import { conditionCategories, FilterSelectPlaceholder } from '../../constants';
import { getWellKnownNestedProperty, getWellKnownProperty } from '../../utils/getWellKnownProperty';
import {
  deletedPropertyRegex,
  getSentenceCase,
  splitNamedSpacedAttributeWithDisplayName,
} from '../../utils/stringUtils';
import { ChangeFilterTypeFn } from '../SegmentCondition/components';

import { useVendorAttributes } from './useVendorAttributes';

const PU_MINIMUM_FILTER_SELECTOR_WIDTH = '250px';

const LOCATION_DISTANCE_SUPPORTED_COUNTRIES = [CountryCode.US, CountryCode.CA];
const LOCATION_SUPPORTED_COUNTRIES = [
  CountryCode.US,
  CountryCode.CA,
  CountryCode.GB,
  CountryCode.AU,
];
const SWITCH_TO_ACTIVITIES: UIAction = 'ui:switch-to-activities';

interface FilterByAttributeSelectorProps {
  value: FilterValue;
  preferenceKey?: string;
  country: string | null;
  attribute?: string;
  onChange: (
    filter: FilterValue,
    optionValue?: string,
    displayName?: string,
    nestedOptionValue?: string
  ) => void;
  segmentOptions: SegmentOptions;
  disabled?: boolean;
  customPropertyDeleted?: boolean;
  customPropertyDisplayName?: string;
  segmentableAttributesByVendor: SegmentableAttributes;
  changeFilterType?: ChangeFilterTypeFn;
  isEditing: boolean;
}

const FilterByAttributeSelector: FC<FilterByAttributeSelectorProps> = ({
  value,
  segmentableAttributesByVendor,
  attribute,
  onChange,
  segmentOptions,
  disabled,
  customPropertyDeleted,
  customPropertyDisplayName,
  country,
  preferenceKey,
  changeFilterType,
  isEditing,
}) => {
  const {
    klaviyoEmailOptions,
    klaviyoLocationOptions,
    klaviyoProfileOptions,
    mParticleProfileOptions,
    punchhProfileOptions,
    rechargeProfileOptions,
    segmentIoProfileOptions,
    shopifyEmailOptions,
    shopifyShoppingOptions,
    smileProfileOptions,
    yotpoProfileOptions,
  } = useVendorAttributes(segmentableAttributesByVendor);
  const { customAttributeOptions, deviceOptions, smsOptions } =
    segmentOptions.subscriberAttributeOptions;
  const { subscriberPreferenceGroups } = smsOptions;
  const { deviceTypes, operatingSystems } = deviceOptions;

  const enableLanguageFilter = useCompanyFeatureFlag('ENABLE_SEGMENT_ON_LANGUAGE');
  const enableRegionFilter = useCompanyFeatureFlag('ENABLE_SEGMENT_ON_REGION');
  const enableWellKnownProperties = useCompanyFeatureFlag(
    'ENABLE_SEGMENTATION_WELL_KNOWN_PROP_FILTER'
  );
  const attentiveEmailEventsEnabled = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const enableLocationGroupInternational = useCompanyFeatureFlag(
    'ENABLE_SEGMENT_LOCATION_ATTRIBUTES_INTL'
  );
  const isEmailOnly = useCompanyFeatureFlag('ENABLE_EMAIL_ONLY_COMPANY');
  const hidePreferencesWhenEditing = useCompanyFeatureFlag(
    'HIDE_SEGMENT_BUILDER_PREFERENCES_CONDITIONS'
  );
  const enableSwitchToActivities = useIsOperatorUIEnabled();

  const wellKnownKlaviyoLastOpened =
    enableWellKnownProperties &&
    getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.KLAVIYO_LAST_EMAIL_OPEN);
  const wellKnownMailchimpMemberRating =
    enableWellKnownProperties &&
    getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.MAILCHIMP_MEMBER_RATING);
  const wellKnownMailExchange = getWellKnownProperty(
    segmentOptions,
    WellKnownPropertyTypes.MAIL_EXCHANGE
  );
  const wellKnownCountry = getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.COUNTRY);
  const wellKnownLocale = getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.LOCALE);
  const wellKnownLanguage = getWellKnownNestedProperty(
    wellKnownLocale,
    WellKnownPropertyLocaleTypes.LANGUAGE
  );

  const attentiveCustomOptions = customAttributeOptions.filter((opt) => {
    return !opt.deleted && SupportedCustomAttributeDataType.includes(opt.type);
  });

  const displayValueOverride =
    customPropertyDeleted && customPropertyDisplayName
      ? customPropertyDisplayName.replace(deletedPropertyRegex, '')
      : undefined;

  const displayRootLocationFields =
    LOCATION_DISTANCE_SUPPORTED_COUNTRIES.includes(country as CountryCode) ||
    (enableLocationGroupInternational &&
      LOCATION_SUPPORTED_COUNTRIES.includes(country as CountryCode));
  const displayLocationDistance = LOCATION_DISTANCE_SUPPORTED_COUNTRIES.includes(
    country as CountryCode
  );
  const displayLocationGroup = displayRootLocationFields || enableRegionFilter;

  const displayDeviceGroup = deviceTypes || operatingSystems;
  const displaySubscriberPreferencesGroup =
    isEditing && !hidePreferencesWhenEditing && subscriberPreferenceGroups.length > 0;

  const displayEmailGroup =
    attentiveEmailEventsEnabled ||
    shopifyEmailOptions.length > 0 ||
    klaviyoEmailOptions.length > 0 ||
    wellKnownKlaviyoLastOpened;
  const displayShopifyShoppingGroup = shopifyShoppingOptions.length > 0;
  const displayYotpoGroup = yotpoProfileOptions.length > 0;
  const displaySegmentIoGroup = segmentIoProfileOptions.length > 0;
  const displayPunchhGroup = punchhProfileOptions.length > 0;
  const displayMParticleGroup = mParticleProfileOptions.length > 0;
  const displaySmileGroup = smileProfileOptions.length > 0;
  const displayRechargeGroup = rechargeProfileOptions.length > 0;
  const displayProfileGroup = klaviyoProfileOptions.length > 0 || wellKnownMailchimpMemberRating;
  const displayCustomAttributesGroup = attentiveCustomOptions.length > 0;

  const formatDetailSelectValue = useMemo(() => {
    if (value === SubscriberPreference.SUBSCRIBER_PREFERENCES && preferenceKey) {
      return `${SubscriberPreference.SUBSCRIBER_PREFERENCES}:${preferenceKey}`;
    }

    if (value === UserPropertyType.CUSTOM_PROPERTY_ID && attribute) {
      return `${UserPropertyType.CUSTOM_PROPERTY_ID}:${attribute}:${customPropertyDisplayName}`;
    }

    if (value === UserPropertyType.WELL_KNOWN_PROPERTY && attribute) {
      return `${UserPropertyType.WELL_KNOWN_PROPERTY}:${attribute}`;
    }

    return value;
  }, [value, attribute, preferenceKey, customPropertyDisplayName]);

  const handleChange = useCallback(
    (condition: string) => {
      if (condition === SWITCH_TO_ACTIVITIES) {
        if (!changeFilterType) {
          throw new Error('Unable to switch to activities');
        }

        changeFilterType(FilterType.ACTION);
        return;
      }
      if (condition && condition.startsWith(SubscriberPreference.SUBSCRIBER_PREFERENCES)) {
        const [, preference] = condition.split(':');
        onChange(SubscriberPreference.SUBSCRIBER_PREFERENCES, preference);
        return;
      }
      if (condition && condition.startsWith(UserPropertyType.CUSTOM_PROPERTY_ID)) {
        const [, optionValue, displayName] = splitNamedSpacedAttributeWithDisplayName(condition);
        onChange(UserPropertyType.CUSTOM_PROPERTY_ID, optionValue, displayName);
        return;
      }
      if (condition && condition.startsWith(UserPropertyType.WELL_KNOWN_PROPERTY)) {
        const [, optionValue, nestedOptionValue] = condition.split(':');
        onChange(UserPropertyType.WELL_KNOWN_PROPERTY, optionValue, undefined, nestedOptionValue);
        return;
      }
      if (condition && condition.startsWith(Vendor.RECHARGE)) {
        const childAttributeName = condition.replace(`${Vendor.RECHARGE}:`, '');
        const matchingOption = rechargeProfileOptions.find(
          ({ attributeName }) => attributeName === childAttributeName
        );
        if (matchingOption) {
          onChange(condition as FilterValue, undefined, matchingOption.displayName);
          return;
        }
      }

      onChange(condition as FilterValue);
    },
    [onChange, rechargeProfileOptions, changeFilterType]
  );

  return (
    <Box css={{ minWidth: PU_MINIMUM_FILTER_SELECTOR_WIDTH }} className="pendo-ignore">
      <SearchableDetailSelect
        placeholder={displayValueOverride || FilterSelectPlaceholder.ATTRIBUTE}
        value={formatDetailSelectValue}
        onChange={handleChange}
        state={customPropertyDeleted ? 'error' : 'normal'}
        disabled={disabled || customPropertyDeleted}
        size="small"
        country={country as SupportedCountryValues}
        DetailPane={DetailPane}
        containerId="segment-body-container"
      >
        {enableSwitchToActivities && (
          <SearchableDetailSelect.IconItem value={SWITCH_TO_ACTIVITIES} name="Sync">
            Switch to activities
          </SearchableDetailSelect.IconItem>
        )}
        {enableSwitchToActivities && <Separator />}
        {displayLocationGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.LOCATION}>
            {displayRootLocationFields && (
              <SearchableDetailSelect.IconItem value={PropertiesFilter.CITY} name="Person">
                City
              </SearchableDetailSelect.IconItem>
            )}
            {displayRootLocationFields && (
              <SearchableDetailSelect.IconItem value={PropertiesFilter.STATE} name="Person">
                {getSentenceCase(getStateLabel(country))}
              </SearchableDetailSelect.IconItem>
            )}
            {displayRootLocationFields && (
              <SearchableDetailSelect.IconItem
                value={PropertiesFilter.BULK_POSTAL_CODES}
                name="Person"
              >
                {getSentenceCase(getPostalCodeLabel(country))}
              </SearchableDetailSelect.IconItem>
            )}
            {displayLocationDistance && (
              <SearchableDetailSelect.IconItem name="Person" value={PropertiesFilter.DISTANCE}>
                Distance
              </SearchableDetailSelect.IconItem>
            )}
            {(displayRootLocationFields || enableRegionFilter) && (
              <SearchableDetailSelect.IconItem name="Person" value={PropertiesFilter.COUNTRY}>
                {enableRegionFilter ? 'Country, recent' : 'Country'}
              </SearchableDetailSelect.IconItem>
            )}
            {enableRegionFilter && wellKnownCountry && (
              <SearchableDetailSelect.IconItem
                key={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownCountry.optionValue}`}
                value={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownCountry.optionValue}`}
                name="Person"
              >
                Country, residence
              </SearchableDetailSelect.IconItem>
            )}
            {enableLanguageFilter && wellKnownLanguage && (
              <SearchableDetailSelect.IconItem
                key={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.LOCALE}:${WellKnownPropertyLocaleTypes.LANGUAGE}`}
                value={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.LOCALE}:${WellKnownPropertyLocaleTypes.LANGUAGE}`}
                name="Person"
              >
                Language
              </SearchableDetailSelect.IconItem>
            )}
            {klaviyoLocationOptions.map(({ attributeName, displayName }) => (
              <SearchableDetailSelect.ThirdPartyIconItem
                key={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${attributeName}`}
                value={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${attributeName}`}
                name="KlaviyoColor"
              >
                {getSentenceCase(displayName)}
              </SearchableDetailSelect.ThirdPartyIconItem>
            ))}
          </SearchableDetailSelect.Group>
        )}
        {displayLocationGroup && !isEmailOnly && <Separator />}
        {!isEmailOnly && (
          <SearchableDetailSelect.Group label={conditionCategories.TEXT}>
            <SearchableDetailSelect.IconItem value={SignUpMethodFilter.TEXT} name="HandWaving">
              Text sign-up method
            </SearchableDetailSelect.IconItem>
          </SearchableDetailSelect.Group>
        )}

        {displayEmailGroup && <Separator />}
        {displayEmailGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.EMAIL}>
            {attentiveEmailEventsEnabled && (
              <SearchableDetailSelect.IconItem value={SignUpMethodFilter.EMAIL} name="HandWaving">
                Email sign-up method
              </SearchableDetailSelect.IconItem>
            )}
            {attentiveEmailEventsEnabled && wellKnownMailExchange && (
              <SearchableDetailSelect.IconItem
                key={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownMailExchange.optionValue}`}
                value={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownMailExchange.optionValue}`}
                name="EnvelopeFilled"
              >
                Mailbox provider
              </SearchableDetailSelect.IconItem>
            )}
            {shopifyEmailOptions.map((property) => (
              <SearchableDetailSelect.ThirdPartyIconItem
                key={`${Vendor.SHOPIFY}:${property.attributeName}`}
                value={`${Vendor.SHOPIFY}:${property.attributeName}`}
                name="ShopifyColor"
              >
                {getSentenceCase(property.displayName)}
              </SearchableDetailSelect.ThirdPartyIconItem>
            ))}
            {klaviyoEmailOptions.map((property) => (
              <SearchableDetailSelect.ThirdPartyIconItem
                key={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${property.attributeName}`}
                value={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${property.attributeName}`}
                name="KlaviyoColor"
              >
                {getSentenceCase(property.displayName)}
              </SearchableDetailSelect.ThirdPartyIconItem>
            ))}
            {wellKnownKlaviyoLastOpened && (
              <SearchableDetailSelect.ThirdPartyIconItem
                name="KlaviyoColor"
                key={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownKlaviyoLastOpened.optionValue}`}
                value={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownKlaviyoLastOpened.optionValue}`}
              >
                {wellKnownKlaviyoLastOpened.displayName}
              </SearchableDetailSelect.ThirdPartyIconItem>
            )}
          </SearchableDetailSelect.Group>
        )}

        {displayDeviceGroup && <Separator />}
        {displayDeviceGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.DEVICE}>
            {deviceTypes && deviceTypes.length > 0 && (
              <SearchableDetailSelect.IconItem
                value={PropertiesFilter.DEVICE_TYPE}
                name="MobileDisplay"
              >
                Device type
              </SearchableDetailSelect.IconItem>
            )}
            {operatingSystems && operatingSystems.length > 0 && (
              <SearchableDetailSelect.IconItem
                value={PropertiesFilter.OPERATING_SYSTEM}
                name="MobileDisplay"
              >
                Operating system
              </SearchableDetailSelect.IconItem>
            )}
          </SearchableDetailSelect.Group>
        )}

        {displaySubscriberPreferencesGroup && <Separator />}
        {displaySubscriberPreferencesGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.PREFERENCES}>
            {subscriberPreferenceGroups.map(({ displayName, optionValue }) => (
              <SearchableDetailSelect.IconItem
                key={`preference-${optionValue}`}
                value={`${SubscriberPreference.SUBSCRIBER_PREFERENCES}:${optionValue}`}
                name="Person"
              >
                {displayName}
              </SearchableDetailSelect.IconItem>
            ))}
          </SearchableDetailSelect.Group>
        )}

        {displayShopifyShoppingGroup && <Separator />}
        {displayShopifyShoppingGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.SHOPPING}>
            {shopifyShoppingOptions.map((property) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.SHOPIFY}:${property.attributeName}`}
                  value={`${Vendor.SHOPIFY}:${property.attributeName}`}
                  name="ShopifyColor"
                >
                  {getSentenceCase(property.displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displayProfileGroup && <Separator />}
        {displayProfileGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.PROFILE}>
            {klaviyoProfileOptions.map(({ attributeName, displayName }) => (
              <SearchableDetailSelect.ThirdPartyIconItem
                key={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${attributeName}`}
                value={`${Vendor.PROFILE_VENDOR_KLAVIYO}:${attributeName}`}
                name="KlaviyoColor"
              >
                {getSentenceCase(displayName)}
              </SearchableDetailSelect.ThirdPartyIconItem>
            ))}
            {wellKnownMailchimpMemberRating && (
              <SearchableDetailSelect.ThirdPartyIconItem
                name="MailchimpColor"
                key={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownMailchimpMemberRating.optionValue}`}
                value={`${UserPropertyType.WELL_KNOWN_PROPERTY}:${wellKnownMailchimpMemberRating.optionValue}`}
              >
                {wellKnownMailchimpMemberRating.displayName}
              </SearchableDetailSelect.ThirdPartyIconItem>
            )}
          </SearchableDetailSelect.Group>
        )}

        {displayMParticleGroup && <Separator />}
        {displayMParticleGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.MPARTICLE}>
            {mParticleProfileOptions.map(({ attributeName, displayName }) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.MPARTICLE}:${attributeName}`}
                  value={`${Vendor.MPARTICLE}:${attributeName}`}
                  name="MParticleColor"
                >
                  {getSentenceCase(displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displayPunchhGroup && <Separator />}
        {displayPunchhGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.PUNCHH}>
            {punchhProfileOptions.map((property) => {
              const displayName =
                property.attributeName === 'marketing_pn_subscription'
                  ? 'Marketing push notification subscription'
                  : property.displayName;
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.PUNCHH}:${property.attributeName}`}
                  value={`${Vendor.PUNCHH}:${property.attributeName}`}
                  name="PunchhColor"
                >
                  {getSentenceCase(displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displayRechargeGroup && <Separator />}
        {displayRechargeGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.RECHARGE}>
            {rechargeProfileOptions.map(({ attributeName, displayName }) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.RECHARGE}:${attributeName}`}
                  value={`${Vendor.RECHARGE}:${attributeName}`}
                  name="ReChargeColor"
                >
                  {getSentenceCase(displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displaySegmentIoGroup && <Separator />}
        {displaySegmentIoGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.SEGMENT_IO}>
            {segmentIoProfileOptions.map((property) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.SEGMENT_IO}:${property.attributeName}`}
                  value={`${Vendor.SEGMENT_IO}:${property.attributeName}`}
                  name="SegmentIOColor"
                >
                  {getSentenceCase(property.displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displaySmileGroup && <Separator />}
        {displaySmileGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.SMILE}>
            {smileProfileOptions.map(({ attributeName, displayName }) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.SMILE}:${attributeName}`}
                  value={`${Vendor.SMILE}:${attributeName}`}
                  name="SmileColor"
                >
                  {getSentenceCase(displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displayYotpoGroup && <Separator />}
        {displayYotpoGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.YOTPO}>
            {yotpoProfileOptions.map((property) => {
              return (
                <SearchableDetailSelect.ThirdPartyIconItem
                  key={`${Vendor.YOTPO}:${property.attributeName}`}
                  value={`${Vendor.YOTPO}:${property.attributeName}`}
                  name="YotpoColor"
                >
                  {getSentenceCase(property.displayName)}
                </SearchableDetailSelect.ThirdPartyIconItem>
              );
            })}
          </SearchableDetailSelect.Group>
        )}

        {displayCustomAttributesGroup && <Separator />}
        {displayCustomAttributesGroup && (
          <SearchableDetailSelect.Group label={conditionCategories.CUSTOM_ATTRIBUTES}>
            {attentiveCustomOptions.map(({ displayName, optionValue }) => (
              <SearchableDetailSelect.IconItem
                key={`custom-attr-${optionValue}`}
                value={`${UserPropertyType.CUSTOM_PROPERTY_ID}:${optionValue}:${displayName}`}
                name="Person"
              >
                {displayName}
              </SearchableDetailSelect.IconItem>
            ))}
          </SearchableDetailSelect.Group>
        )}
      </SearchableDetailSelect>
    </Box>
  );
};

export default FilterByAttributeSelector;
